body {
    background-color: #fff
}

.auth {
    height: 100vh;
    overflow-x: hidden
}

.auth .auth-right {
    height: 100%;
    background: url(./bg.jpg), linear-gradient(90deg, #2d499d, #3f5491)
}

.auth .auth-left {
    padding: 5rem 8rem
}

.auth .auth-left .auth-title {
    font-size: 4rem;
    margin-bottom: 1rem
}

.auth .auth-left .auth-subtitle {
    font-size: 1.7rem;
    line-height: 2.5rem;
    color: #a8aebb
}

.auth .auth-left .auth-logo {
    margin-bottom: 7rem
}

.auth .auth-left .auth-logo img {
    height: 2rem
}
.auth-font-bold{
    color:#2d499d;
    font-weight: bold;
    text-decoration: none;
}
@media screen and (max-width:767px) {
    .auth .auth-left {
        padding: 5rem
    }
}
