.chat{border-radius:5px}
.chat.chat-left .chat-message{
    background-image: linear-gradient( 135deg, #6B73FF 10%, #000DFF 100%);
    float:left!important;
    color:#fff}
.chat .chat-message{
    text-align:left!important;
    float:right!important;
    margin:.1rem 0 1.0rem .1rem!important;
    color:#050404;
    background-image: linear-gradient( 135deg, #FEC163 10%, #DE4313 100%);
    box-shadow:0 2px 6px 0 rgba(0,0,0,.3)!important;
    padding:.75rem .75rem 0rem 1rem!important;
    position:relative!important;
    clear:both!important;
    word-break:break-word!important;
    border-radius:.267rem!important;
}
.chat-message-time{
    text-align: right!important;
    float: right !important;;
    top: 0;
    bottom: 0;
    position:relative;
    font-size: 0.813rem;
    color:#ffffff;
}
.user-avt{
    border:2px solid #fff;
}
.font-bold{
    color:antiquewhite
}